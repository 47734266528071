<template>
    <input class="form-control" :id="pickerId"
            v-model="currentValue"
            xchange="onChangedPicker"
            :name="name"
            />
</template>
<script>
export default {
    props:{
        pickerValue:{
            type:String
        },
        name:{
            type:String
        },
        pickerId:{
            type:String,
            required:true
        },
        startDate:{
            type:String
        },
        endDate:{
            type:String
        }
    },
    watch:{
        currentValue(value){
            this.$emit("update:changedValue", value );
        },
        pickerValue(value){
            this.currentValue = value;
        }
    },
    data(){
        return {
            currentValue:null
        }
    },
    methods:{
        onChangedPicker(){
            // this.$emit("update:changedValue", this.currentValue );
        }
    },
    computed:{
        valuePicker(){
            // this.currentValue = this.pickerValue;
        }
    },
    // created(){
    //     this.currentValue = this.pickerValue;
    // },
    mounted(){
        let dateConfig = {
          language: "id",
          todayHighlight: true,
        //   format: "dd/mm/yyyy hh:mm:ss",
          format: "dd-mm-yyyy hh:mm:ss",
          startDate: this.startDate,
          endDate: this.endDate,
          todayBtn: this.btnToday ? "linked" : false,
          daysOfWeekDisabled: this.daysDisabled
        };
        $(`#${this.pickerId}`).datetimepicker(dateConfig);
        $(`#${this.pickerId}`).change((e)=>{
            this.currentValue = e.target.value;
        });

    }
}
</script>
