export default function FormulaLoyaltyModel() {
    return {
        id:null,
        priority:null,
        formula_name:null,
        start_date:null,
        end_date:null,
        transaction_amount_min:null,
        transaction_amount_max:null,
        point:0,
        point_percentage:0,
        transaction_min:null,
        transaction_max:null,
        point_min:null,
        point_max:null,
        point_type:[],
        tiers:[],
        };
}
