<template>
    <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    >
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{
                            this.formulaLoyaltyModel.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.formulaLoyalty") }}</h5>
            </div>
            <form @submit.prevent="submitData()">
                <div class="modal-body">
                    <input
                    type="hidden"
                    class="form-control"
                    v-model.number="formulaLoyaltyModel.id"
                    />

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label for="">{{ $t('formulaLoyalty.formula_name') }}* :</label>
                            <input v-model="formulaLoyaltyModel.formula_name"
                            v-validate="{required:true,max:100 }" class="form-control"
                            :class="{'is-invalid':errors.has('formula_name')}"
                            :disabled="modeView"
                            name="formula_name" :data-vv-as="$t('formulaLoyalty.formula_name')"/>
                            <div v-show="errors.first('formula_name')" class="custom-invalid-feedback" >{{ errors.first('formula_name') }}</div>
                        </div>

                        <div class="col-md-3 form-group">
                            <label for="">{{ $t('formulaLoyalty.priority') }}* :</label>
                            <input v-model="formulaLoyaltyModel.priority" v-validate="'required'" class="form-control"
                            :class="{'is-invalid':errors.has('priority')}"
                            :disabled="modeView"
                            type="number" pattern="[0-9]"  step="1" min="0"
                            name="priority" :data-vv-as="$t('formulaLoyalty.priority')"/>
                            <div v-show="errors.first('priority')" class="custom-invalid-feedback" >{{ errors.first('priority') }}</div>
                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Jenis Poin</label>
                                    <div class="form-group">
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" v-model="point_types" value="j">Jumlah Poin
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" v-model="point_types"  value="p">Persentase Poin
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Nilai {{ point_types =='j'?'':'Persentase' }} Point</label>
                                    <input class="form-control" :class="{'is-invalid':errors.has('point')}" v-validate="{'required': point_types == 'j'}" v-model="formulaLoyaltyModel.point" name="point" :data-vv-as="$t('formulaLoyalty.point')" type="number" pattern="[0-9]"  step="1" min="0" v-show="point_types =='j'" />
                                    <input class="form-control" :class="{'is-invalid':errors.has('point_percentage')}" v-validate="{'required': point_types == 'p'}" v-model="formulaLoyaltyModel.point_percentage" name="point_percentage" :data-vv-as="$t('formulaLoyalty.point_percentage')" type="number" step="1" pattern="[0-9]" min="0" v-show="point_types =='p'" />
                                    <div v-show="point_types == 'j' && errors.first('point')" class="custom-invalid-feedback" >{{ errors.first('point') }}</div>
                                    <div v-show="point_types == 'p' && errors.first('point_percentage')" class="custom-invalid-feedback" >{{ errors.first('point_percentage') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">

                            <fieldset>
                                <legend>Jumlah Transaksi</legend>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.transaction_amount_min') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.transaction_amount_min"
                                            class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_amount_min')}"
                                        :disabled="modeView"
                                        name="transaction_amount_min" :data-vv-as="$t('formulaLoyalty.transaction_amount_min')"/>
                                        <div v-show="errors.first('transaction_amount_min')" class="custom-invalid-feedback" >{{ errors.first('transaction_amount_min') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.transaction_amount_max') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.transaction_amount_max"
                                            class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_amount_max')}"
                                        :disabled="modeView"
                                        name="transaction_amount_max" :data-vv-as="$t('formulaLoyalty.transaction_amount_max')"/>
                                        <div v-show="errors.first('transaction_amount_max')" class="custom-invalid-feedback" >{{ errors.first('transaction_amount_max') }}</div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6" v-show="point_types=='p'">
                            <fieldset >
                                <legend>Batasan Transaksi</legend>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.transaction_min') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.transaction_min" x_v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_min')}"
                                        :disabled="modeView"
                                        name="transaction_min" :data-vv-as="$t('formulaLoyalty.transaction_min')"/>
                                        <div v-show="errors.first('transaction_min')" class="custom-invalid-feedback" >{{ errors.first('transaction_min') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.transaction_max') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.transaction_max" x_v-validate="'required'" class="form-control"
                                        :class="{'is-invalid':errors.has('transaction_max')}"
                                        :disabled="modeView"
                                        name="transaction_max" :data-vv-as="$t('formulaLoyalty.transaction_max')"/>
                                        <div v-show="errors.first('transaction_max')" class="custom-invalid-feedback" >{{ errors.first('transaction_max') }}</div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    <!--/div>
                    <div class="row"-->
                        <div class="col-md-6">
                            <fieldset>
                                <legend>Batasan Tanggal</legend>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.start_date') }}:</label>
                                        <date-picker
                                        :changedValue.sync="formulaLoyaltyModel.start_date"
                                        :id-date-picker="'start_date_' + formulaLoyaltyModel.id"
                                        :picker-value="formulaLoyaltyModel.start_date"
                                        :place-holder="$t('formulaLoyalty.start_date')"
                                        :is-validate="false"
                                        :isDisabled="modeView"
                                        start-date=""
                                        end-date=""
                                        ></date-picker>
                                        <div v-show="errors.first('start_date')" class="custom-invalid-feedback" >{{ errors.first('start_date') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.end_date') }}:</label>
                                        <date-picker
                                        :changedValue.sync="formulaLoyaltyModel.end_date"
                                        :id-date-picker="'end_date_' + formulaLoyaltyModel.id"
                                        :picker-value="formulaLoyaltyModel.end_date"
                                        :place-holder="$t('formulaLoyalty.end_date')"
                                        :is-validate="false"
                                        :isDisabled="modeView"
                                        start-date=""
                                        end-date=""
                                        ></date-picker>
                                        <div v-show="errors.first('end_date')" class="custom-invalid-feedback" >{{ errors.first('end_date') }}</div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6" v-show="point_types=='p'">
                            <fieldset>
                                <legend>Batasan Poin</legend>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.point_min') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.point_min" v-validate="" class="form-control"
                                        :class="{'is-invalid':errors.has('point_min')}"
                                        :disabled="modeView"
                                        name="point_min" :data-vv-as="$t('formulaLoyalty.point_min')"/>
                                        <div v-show="errors.first('point_min')" class="custom-invalid-feedback" >{{ errors.first('point_min') }}</div>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label for="">{{ $t('formulaLoyalty.point_max') }} :</label>
                                        <input type="number" v-model="formulaLoyaltyModel.point_max" v-validate="" class="form-control"
                                        :class="{'is-invalid':errors.has('point_max')}"
                                        :disabled="modeView"
                                        name="point_max" :data-vv-as="$t('formulaLoyalty.point_max')"/>
                                        <div v-show="errors.first('point_max')" class="custom-invalid-feedback" >{{ errors.first('point_max') }}</div>
                                    </div>

                                </div>

                            </fieldset>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="">{{ $t('formulaLoyalty.tiers') }} :</label>
                            <select-dropdown-multiple
                            :is-disabled="modeView"
                            :render-select="loyaltyTierRenderSelect"
                            repo-name="loyaltyTierRepository"
                            @update:changedValue="onLoyaltyTierSelected"
                            ref="loyaltyTierSelectVue"
                            :selected-dropdown="loyaltyTierIds"
                            :id-select-dropdown="'tiers_' + formulaLoyaltyModel.id"
                            ></select-dropdown-multiple>

                            <div v-show="errors.first('tiers')" class="custom-invalid-feedback" >{{ errors.first('tiers') }}</div>
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="">{{ $t('formulaLoyalty.point_type') }} :</label>
                            <select-dropdown-multiple
                            :render-select="trPointTypeRenderSelect"
                            :isDisabled="modeView"
                            repo-name="transactionPointTypeRepository"
                            @update:changedValue="onTrPointTypeSelected"
                            ref="trPointTypeSelectVue"
                            :id-select-dropdown="'tr_point_type_id_' + formulaLoyaltyModel.id"
                            :selected-dropdown="trPointTypeIds"
                            ></select-dropdown-multiple>

                            <div v-show="errors.first('point_types')" class="custom-invalid-feedback" >{{ errors.first('point_types') }}</div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                    type="button"
                    class="btn btn-clean btn-bold btn-upper btn-font-md"
                    @click="hideModal()"
                    >
                    {{ $t("button.close") }}
                </button>
                <button
                v-if="!modeView"
                type="submit"
                class="btn btn-default btn-bold btn-upper btn-font-md"
                >
                {{ $t("button.save") }}
            </button>
        </div>
    </form>
</div>
</div>
</div>
</template>
<script>
    import FormulaLoyaltyModel from "./../../model/formula-loyalty-model";
    import { Validator } from "vee-validate";
    import VeeValidate from "vee-validate";
    import DatePicker from "./../../../components/_general/DatePicker";
    import DateTimePicker from "./../../components/base/DateTimePicker";
    import SelectDropdownMultiple from "./../../components/base/SelectDropdownMultiple";
    import Photo from "./../../model/photo-model";
    import { mapState, mapMutations, mapActions } from "vuex";
    import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
    import FormulaLoyaltyRepository from '../../repositories/FormulaLoyaltyRepository';
    import BlockUI from "./../../../components/_js/BlockUI";
    const blockUI = new BlockUI();
    const SweetAlert = Vue.extend(SweetAlertTemplate);

    VeeValidate.setMode('passive');
    export default {
        components:{ SelectDropdownMultiple, DatePicker},
        computed: {
            ...mapState("Crud", {
                modalName: state => state.modalName,
                dataEdit: state => state.dataEdit,
                showModalMode: state => state.showModalMode,
                isShowModal: state => state.isShowModal,
            }),
            isDateValid(){
                if(
                    this.formulaLoyaltyModel.start_date!== null
                && this.formulaLoyaltyModel.end_date!== null
                ){
                    let startDate = moment(this.formulaLoyaltyModel.start_date).valueOf();
                    let endDate = moment(this.formulaLoyaltyModel.end_date).valueOf();
                    let isOk =  startDate <= endDate;
                    if(!isOk){
                        $('#end_date_'+this.formulaLoyaltyModel.id).addClass('is-invalid');
                        this.errors.add({
                            field: 'end_date',
                            msg: this.$t('sentences.invalid_end_date'),
                            rule: "required"});
                    }
                    return isOk
                }
                return true
            },
            loyaltyTierIds(){
                if(this.dataEdit){
                    return _.map(this.dataEdit.tiers,'id');
                }
                return [];
            },
            trPointTypeIds(){
                if(this.dataEdit){
                    return _.map(this.dataEdit.point_type,'id');
                }
                return [];
            },
            modeEdit(){
                return (this.dataEdit && this.dataEdit.id) || this.modeView;
            },
            modeView(){
                return this.showModalMode == 'V';
            },
        },
        provide() {
            return { $validator: this.$validator };
        },
        data(){
            return {
                formulaLoyaltyModel:new FormulaLoyaltyModel(),
                photo: new Photo(),
                imgSelected:false,
                point_types:'j',
                pt_value:0
            }
        },
        watch:{
            'formulaLoyaltyModel.end_date'(value){
                $('#end_date_'+this.formulaLoyaltyModel.id).removeClass('is-invalid');
                this.errors.remove('end_date');
            }
        },
        methods: {
            ...mapMutations({
                clearData: "Crud/CLEAR_DATA",
                showModal: "Crud/SHOW_MODAL",
                refreshData: "Crud/REFRESH_TABLE",
                setEditData: "Crud/SET_EDIT_DATA",
                hideModal: "Crud/HIDE_MODAL",
            }),

            getServerError(error, defaultMsg) {
                return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
            },
            loyaltyTierRenderSelect(val){
                return (val.name) ? val.name : '';
            },
            trPointTypeRenderSelect(val){
                return val.type_name;
            },
            onLoyaltyTierSelected(value){
                try{
                    this.formulaLoyaltyModel = Object.assign(this.formulaLoyaltyModel,{
                        tiers:value
                    });
                }
                catch(e){ }

            },

            onTrPointTypeSelected(val){
                try{
                    this.formulaLoyaltyModel = Object.assign(this.formulaLoyaltyModel,{
                        point_types:val
                    });

                }catch(e){

                }

            },
            async update(payload,couponId) {
                var vx = this;
                await FormulaLoyaltyRepository.update(payload,couponId)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                        resolve(response);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Formula Loyalty berhasil diubah', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                        error,
                        "Data Formula Loyalty gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
            },
            async create(payload) {
                var vx = this;
                await FormulaLoyaltyRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                        resolve(response);
                    }).
                    then((response) => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Formula Loyalty berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                            }
                        });
                    }).catch(error=>{

                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                        error,
                        "Data Formula Loyalty gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
            },
            submitData(){
                this.errors.clear();
                this.$validator.validateAll().then(result => {

                    if (result && this.isDateValid) {
                        var vx = this;
                        if (this.formulaLoyaltyModel.id) {

                            let instance = new SweetAlert().showConfirm({
                                swalTitle: "Apakah Anda yakin?",
                                swalText: "Perubahan data akan tersimpan.",
                                swalType: "info",
                                onConfirmButton: function() {
                                    blockUI.blockModal();
                                    vx.formulaLoyaltyModel.tiers = _.map(vx.formulaLoyaltyModel.tiers,"id");
                                    vx.formulaLoyaltyModel.point_types = _.map(vx.formulaLoyaltyModel.point_types,"id");
                                    if(vx.point_types == 'j'){
                                        vx.formulaLoyaltyModel.point_percentage = 0;
                                    }
                                    else{
                                        vx.formulaLoyaltyModel.point = 0;
                                    }
                                    vx.update(vx.formulaLoyaltyModel, vx.formulaLoyaltyModel.id);
                                },
                                onCancelButton: function() {
                                    blockUI.unblockModal();
                                }
                            });
                        } else {
                            let instance = new SweetAlert().showConfirm({
                                swalTitle: "Apakah Anda yakin?",
                                swalText: "Data akan ditambahkan.",
                                swalType: "info",
                                onConfirmButton: function() {
                                    blockUI.blockModal();

                                    let arrToString = ['tiers','point_types'];
                                    const data = {};
                                    _.forEach(vx.formulaLoyaltyModel, (v, k) => {
                                        if(v != null && v != ''){
                                            if(arrToString.indexOf(k) !== -1){
                                                v = _.map(v,'id');
                                                data[k] = _.map(v,'id');
                                            }
                                            data[k] = v;
                                        }
                                    });
                                    vx.create(data);
                                },
                                onCancelButton: function() {
                                    blockUI.unblockModal();
                                }
                            });
                        }

                        return;
                    }
                    else{
                        console.log(this.errors);
                    }
                });
            },
            updateData() {
                if (this.dataEdit) {
                    this.point_types = 'p';
                    if(this.dataEdit.point_percentage == null || parseInt(this.dataEdit.point_percentage) == 0 ){
                        this.point_types = 'j';
                    }

                    this.formulaLoyaltyModel = Object.assign({}, this.dataEdit);
                }

            },
        },

        created(){
            VeeValidate.Validator.extend('greater_than_start_date', {
                getMessage: field => this.$t('sentences.invalid_end_date'),
                validate: value => {
                    let testRegex = new RegExp("^[a-zA-Z0-9-]+$");
                    return testRegex.test(value);
                }
            });
        },
        mounted() {
            if(!this.modeView){

            }
            $("#" + this.modalName).modal();
            this.updateData();
        }
    }
</script>
